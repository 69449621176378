import { render, staticRenderFns } from "./SubGroupEditView.vue?vue&type=template&id=4775c46c&scoped=true&"
import script from "./SubGroupEditView.vue?vue&type=script&lang=js&"
export * from "./SubGroupEditView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4775c46c",
  null
  
)

export default component.exports